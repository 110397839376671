import * as React from "react";
import "../templates/blog.scss";
import { SearchPageQuery } from "graphql-types";
import Hero, { headerStyle } from "src/components/Organisms/Hero/hero";
import { graphql, Link, navigate } from "gatsby";
import moment from "moment";
import Pagination from "rc-pagination";
import { Index } from "lunr"

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome')
const { faCalendarAlt, faTag } = require('@fortawesome/free-solid-svg-icons')

export default class SearchPage extends React.PureComponent<{
  data: SearchPageQuery;
  location: Location;
}> {
  public render() {
    const params = new URLSearchParams(this.props.location.search.slice(1))
    const q = params.get("q") || ""

    if (q == "") {
      return <>
        <Hero {... {
            style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
            header: 'You need to know',
            subLine: '',
            buttonLink: ""
        }} />
      </>;
    }
    
    const { store } = this.props.data.LunrIndex

    const index = Index.load(this.props.data.LunrIndex.index)
    let results = []
    try {
      // Search is a lunr method
      results = index.search(q).map(({ ref }) => {
        // Map search results to an array of {slug, title, excerpt} objects
        return {
          slug: ref,
          ...store[ref],
        }
      })
    } catch (error) {
      console.log(error)
    }
    return (
        <>
          <Hero {... {
              style: headerStyle(require("src/components/Organisms/images/banner/homepage.jpg")),
              header: 'You need to know',
              subLine: '',
              buttonLink: ""
          }} />
          <div className="container-fluid section-padding-tb96 blog-page">
            <div className="container">
              <div className="row align-items-center">
                  <div className="col-sm-12 col-lg-4 order-2 align-self-start">
                    <form action="/search/">
                      <p><input className="form-control" type="text" name="q" placeholder="Search ..." /></p>
                      <input type="submit" className="custom-button theme btn btn-secondary" value="Search" />
                    </form>
                  </div>
                  <div className="col-sm-12 col-lg-8 ">
                  <h3>Search result of "{q}":</h3>
                  {
                    results.slice(0, 10).map((post, i) => (
                      <section key={q + post.title + i}>
                        <img className="blog-pic" src={"/images/blog-images/undefined.jpg"} />
                        <h2><Link to={post.slug || ''} dangerouslySetInnerHTML={{__html: post.title || ''}}></Link></h2>
                        <div className="blog-metas">
                          <div className="date">
                            <FontAwesomeIcon icon={faCalendarAlt} color="#f58d39" />
                            {moment(post.date).format('YYYY-MM-DD')}
                          </div>
                        </div>
                        <div className="sub-cont__contet-cont__bodytxt" dangerouslySetInnerHTML={{__html: post.excerpt || ''}}>
                        </div>
                      </section>
                    ))
                  }
                  </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

export const pageQuery = graphql`
  query SearchPage {
    LunrIndex
  }
`;
//back to original version